var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3e1be530a4d70d7967af4429930ae3213fb7c080"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import {
  init,
  Breadcrumb,
  BrowserTracing,
  EventHint,
  Replay
} from '@sentry/nextjs';
import { parseBool } from '@utils';

const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV;
const SENTRY_ENABLED = process.env.NEXT_PUBLIC_SENTRY_ENABLED;
const SENTRY_DSN = process.env.NEXT_PUBLIC_APP_DASHBOARD__SENTRY_DSN;

init({
  enabled: parseBool(SENTRY_ENABLED),
  environment: APP_ENV,
  dsn: SENTRY_DSN,
  tracesSampleRate: 0.01,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.1,
  integrations: [new BrowserTracing(), new Replay()],
  ignoreErrors: [
    'ResizeObserver loop completed with undelivered notifications'
  ],
  denyUrls: [
    // Extensions
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    /^safari-extension:\/\//i,
    /^resource:\/\//i,
    // Facebook
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /connect\.facebook\.net\/en_US\/fbevents\.js/i,
    // Yandex
    /mc\.yandex\.com/i,
    /mc\.yandex\.ru\/metrika\/tag\.js/i,
    // Google
    /analytics\.google\.com\/g\/collect/i,
    /googletagmanager\.com\/gtag\/js/i,
    // Twitter
    /static\.ads-twitter\.com\/uwt\.js/i,
    // HelpScout
    /beaconapi\.helpscout\.net/i,
    /beacon-v2\.helpscout\.net\/static\/js/i
  ],
  beforeBreadcrumb(breadcrumb: Breadcrumb) {
    if (breadcrumb.category === 'xhr') {
      return null;
    }
    return breadcrumb;
  },
  beforeSend: (error, hint) => {
    if (isNetworkError(hint)) {
      if (
        lookThroughLatestBreadcrumbs(
          error.breadcrumbs,
          (breadcrumb) =>
            isErroneousNetworkBreadcrumb(breadcrumb) &&
            hasIgnoredNetworkBreadcrumb(breadcrumb, [])
        )
      ) {
        return null;
      }

      return error;
    }

    return error;
  }
});

function isNetworkError(hint: EventHint) {
  const NETWORK_ERROR_VALUES = new Set([
    'Failed to fetch', // chrome
    'NetworkError when attempting to fetch resource.', // firefox
    'Load failed' // safari
  ]);

  return NETWORK_ERROR_VALUES.has(hint.originalException as string);
}

function lookThroughLatestBreadcrumbs(
  breadcrumbs: Breadcrumb[] = [],
  callback: (breadcrumb: Breadcrumb) => boolean
) {
  const LOOP_TS_LIMIT = 5000;
  const now = Date.now();

  return breadcrumbs
    .reverse()
    .filter(
      ({ timestamp }) => timestamp && now - timestamp * 1000 < LOOP_TS_LIMIT
    )
    .some(callback);
}

function isErroneousNetworkBreadcrumb(breadcrumb: Breadcrumb) {
  return (
    breadcrumb.level === 'error' &&
    ['xhr', 'fetch'].includes(breadcrumb.category || '')
  );
}

function hasIgnoredNetworkBreadcrumb(
  breadcrumb: Breadcrumb,
  ignoredURLs: string[]
) {
  const url = breadcrumb.data?.url as string | undefined;

  return url
    ? ignoredURLs.some((ignoredURL) => url.startsWith(ignoredURL))
    : false;
}
